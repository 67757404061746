/**
 * Perform action when elements are in view
 * @param {Array} items DOM elements to observe
 * @param {Object} userProps Object of options
 * @returns void
 */
export default function intersectElements(items = [], userProps = { observerProps: {} }) {
    if (!("IntersectionObserver" in window)) return;
    const defaultProps = {
        observerProps: {
            threshold: [0.5] // 50% of element is in viewport
        },
        on: undefined,
        off: undefined
    };

    const props = { ...defaultProps, ...userProps };
    const observerProps = {
        ...defaultProps.observerProps,
        ...userProps.observerProps
    };
    const observerInst = new IntersectionObserver(observe, observerProps);

    function observe(items, observer) {
        items.forEach((item) => {
            if (item.isIntersecting) {
                if (props.on) props.on(item, observerInst);
            } else {
                if (props.off) props.off(item, observerInst);
            }
        });
    }

    items.forEach((item) => observerInst.observe(item));
}
